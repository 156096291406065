import { css } from '@emotion/react';

export const wrapper = css`
    grid-area: main;
    display: grid;
    
    
    grid-template-columns: 50% 1fr;
    grid-column-gap: 90px;
`;

