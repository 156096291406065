import weekend from './weekend';
import helloWorld from './hello-wold';
import jsConsole from './js-console';

const content = [
    weekend,
    helloWorld,
    jsConsole,
];

export default content;