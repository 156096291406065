import { css } from '@emotion/react';

export const wrapper = css`
    grid-area: header;
`;


export const title = css`
    margin: 0;
`;

